<template>
  <div class="main-page">
    <el-card
     class="search-card"
     shadow="never"
    >
      <el-row>
        <el-form
         class="main-search"
         label-width="100px"
         :style="{ marginTop: '5px' }"
        >
          <el-col
           :span="8"
           :style="{ marginTop: '15px' }"
          >
            <el-form-item
             prop="query"
             label-width="0px"
            >
              <el-input
               ref="search"
               v-model="query"
               placeholder="Search for company or person..."
               @keyup.enter.native="handleDoSearch(query)"
              >
                <i
                 slot="suffix"
                 class="el-input__icon el-icon-search"
                 :style="{ cursor: 'pointer' }"
                 @click="handleDoSearch(query)"
                ></i>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
             prop="country"
             label="Country"
            >
              <SelectCountry
               v-model="country"
               num-code-key="Id"
               label-key="Name"
               :countries="countries"
               :style="{ width: '250px' }"
              ></SelectCountry>
            </el-form-item>
            <el-form-item
             prop="filter"
             label="Filter"
            >
              <el-select
               v-model="simpleFilter"
               value-key="label"
               :style="{ width: '250px' }"
              >
                <el-option
                 v-for="(f, $index) in simpleFilters"
                 :key="f.label"
                 :label="f.label"
                 :value="$index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <img
       class="logo"
       :src="logo"
       alt="FindProspects Logo"
      >
      <ButtonBar>
        <!-- Removed 22.aug 2024, hasn't been used by anyone for over a year -->
        <!-- <el-button
         :style="{ float: 'right' }"
         icon="el-icon-message"
         @click="$router.push('/feedback')"
        >Send feedback</el-button> -->
        <el-button
         :style="{ float: 'right' }"
         icon="el-icon-s-tools"
         :disabled="!isAdmin"
         @click="$router.push('/admin')"
        >Settings</el-button>
      </ButtonBar>
    </el-card>
    <el-card
     class="results-card"
     shadow="never"
    >
      <div class="table-wrapper">
        <el-table
         ref="resultsTable"
         height="100%"
         max-height="100%"
         border
         row-key="uuid"
         :empty-text="resultsEmptyText"
         :data="filteredResults"
         @row-click="handleRowClick"
         @row-dblclick="handleRowDoubleClick"
         @select="handleSelectRow"
        >
          <el-table-column
           type="selection"
           width="37"
           reserve-selection
           :selectable="(row) => !row.imported"
          >
          </el-table-column>
          <el-table-column
           prop="duplicates"
           width="46"
           sortable
           :sort-method="handleSortByDuplicates"
           class-name="icon-container"
          >
            <template slot-scope="{ row }">
              <i
               v-if="row.imported"
               class="el-icon-check"
              ></i>
              <i
               v-if="row.fetchingDuplicates"
               class="el-icon-loading"
              ></i>
              <i
               v-else-if="!row.duplicates"
               class="el-icon-question"
              >
              </i>
              <el-tooltip
               v-else-if="row.duplicates && row.duplicates.length"
               placement="right"
               :content="`${row.duplicates.length} possible duplicates`"
               :tabindex="-1"
              >
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
           prop="entity_type"
           label="Type"
           width="70"
           sortable
          >
            <template slot-scope="{ row }">
              <i
               class="so-icon"
               :class="entityIcon(row.entity_type)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
           prop="name"
           label="Name"
           resizable
           sortable
          ></el-table-column>
          <el-table-column
           prop="org_no"
           label="VATno"
           width="80"
           sortable
           align="right"
          ></el-table-column>
          <el-table-column
           prop="street_address"
           label="Street address"
           width="200"
           sortable
           resizable
          ></el-table-column>
          <el-table-column
           prop="street_zipcode"
           label="Zip"
           width="65"
           sortable
           align="right"
          ></el-table-column>
          <el-table-column
           prop="street_city"
           label="City"
           width="150"
           sortable
          ></el-table-column>
          <el-table-column
           prop="postal_address"
           label="Postal address"
           width="150"
           sortable
           resizable
          ></el-table-column>
          <el-table-column
           prop="postal_zipcode"
           label="Zip"
           width="65"
           sortable
           align="right"
          ></el-table-column>
          <el-table-column
           prop="postal_city"
           label="City"
           width="150"
           sortable
          ></el-table-column>
          <el-table-column
           prop="source"
           label="Datasource"
           width="150"
           sortable
          ></el-table-column>
        </el-table>
      </div>
      <ButtonBar>
        <el-row
         type="flex"
         justify="space-between"
        >
          <el-col :span="8">
            <el-tooltip
             :disabled="!!selectedProspects.length"
             content="No prospects selected"
             placement="top-start"
            >
              <span>
                <el-button
                 type="primary"
                 icon="el-icon-plus"
                 :disabled="!(selectedProspects.length >= 2)"
                 @click="handleImportSelected"
                >Bulk import selected</el-button>
              </span>
            </el-tooltip>
            <el-button
             :disabled="!searchResults.length || fetchingAllDuplicates"
             type="primary"
             icon="el-icon-question"
             @click="handleCheckVisibleForDuplicates"
            >Check list for duplicates</el-button>
            <el-button
             type="primary"
             icon="el-icon-circle-close"
             @click="handleClearSelection()"
            >Clear selection</el-button>
          </el-col>
          <el-col :span="8">
            <el-row
             class="count-wrapper"
             v-if="searchResults.length"
             type="flex"
             justify="center"
            >
              <el-col :span="8">
                Selected: {{`${selectedProspects.length}/${searchResults.length}`}}
              </el-col>
              <el-col :span="8">
                Showing: {{`${filteredResults.length}/${searchResults.length}`}}
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="8">
            <div
             class="filter-controls"
             v-if="searchResults.length"
            >
              <el-checkbox-group v-model="filters">
                <el-checkbox
                 v-for="filter in resultsFilters"
                 :key="filter.label"
                 :label="filter.value"
                >{{filter.label}}</el-checkbox>
              </el-checkbox-group>
            </div>
          </el-col>
        </el-row>
      </ButtonBar>
    </el-card>
    <!-- Child render (Import-dialogs) -->
    <!-- 'clear-from-selection' event comes from ImportSelected -->
    <!-- 'imported' event comes from ImportSelected -->
    <router-view
     @clear-from-selection="handleClearSelection"
     @imported="handleClearSelection"
    />
  </div>
</template>
<script>
import logo from '@/assets/logo.png'
import ButtonBar from '@/components/ButtonBar'
import SelectCountry from '@/components/SelectCountry'

import { get, sync, call } from 'vuex-pathify'

export default {
  components: {
    ButtonBar,
    SelectCountry
  },
  data () {
    return {
      logo,
      filters: [],
      searchPerformed: false,
      fetchingAllDuplicates: false
    }
  },
  computed: {
    ...get([
      'MAX_IMPORT',
      'entityIcon',
      'countries',
      'simpleFilters',
      'resultsFilters',
      'searchResults',
      'importViewByEntityType',
      'countryAlphaByNum',
      'countryNameByNum',
      'isAdmin'
    ]),
    query: sync('query'),
    simpleFilter: sync('preferences@simpleFilter'),
    selectedProspects: sync('selected@prospects'),
    country: {
      /* eslint-disable-next-line */
      get() {
        return this.$store.state.preferences.country
      },
      /* eslint-disable-next-line */
      set(value) {
        return this.$store.commit('country', value)
      }
    },
    filteredResults () {
      const { searchResults, filters } = this
      return filters.length
        ? searchResults.filter(i => filters.every(filter => filter(i)))
        : searchResults
    },
    resultsEmptyText () {
      const { searchPerformed, searchResults, resultsFilters, filters } = this
      if (searchPerformed) {
        if (searchResults.length && filters.length) {
          const appliedFilters = resultsFilters.filter(f => filters.includes(f.value))
          return `No results to show. Current applied filters: ${appliedFilters.map(f => f.label).join(', ')}`
        }
        return 'Did not find any results based on the search query'
      }
      return 'Perform a search - the results will be displayed here... .NET 8 version!!!'
    }
  },
  methods: {
    ...call([
      'message',
      'doSearch',
      'checkForDuplicates'
    ]),
    handleClearSelection (data) {
      if (data) {
        if (data instanceof Array) {
          // Everything previously selected is imported; clear everything
          this.$refs.resultsTable.clearSelection()
          this.selectedProspects = []
        } else {
          // 'data' is a single row
          this.$refs.resultsTable.toggleRowSelection(data, false)
          this.selectedProspects = this.selectedProspects.filter(i => i.uuid !== data.uuid)
        }
      } else {
        // If the parameter is 'undefined', clear everything
        this.$refs.resultsTable.clearSelection()
        this.selectedProspects = []
      }
    },
    handleSelectRow (selection, row) {
      if (selection.length > this.MAX_IMPORT) {
        this.message({
          type: 'info',
          message: 'Max prospects for import selected. Remove selected rows or import multiple times.',
          duration: 5000
        })
        this.handleClearSelection(row)
      } else {
        this.selectedProspects = selection
      }
    },
    async handleDoSearch (query) {
      // We need to empty the reserved selection on the el-table when the user
      // performs a new search. This can't be done exclusively in the store.
      this.handleClearSelection()
      await this.doSearch(query)
      this.searchPerformed = true
      // this.handleCheckVisibleForDuplicates()
    },
    handleRowClick (row) {
      if (!row.duplicates && !row.fetchingDuplicates) {
        this.checkForDuplicates(row)
      }
    },
    handleRowDoubleClick (row, column, event) {
      if (row.imported) {
        this.message({
          type: 'warning',
          message: 'Prospect already imported.'
        })
      } else {
        // Ignore behaviour if double clicking on the 'selection' column
        const clickedSelectionCell = !!event.target.closest('.el-table-column--selection')
        if (!clickedSelectionCell) {
          const view = this.importViewByEntityType(row.entity_type)
          this.$router.push({ ...view, params: { uuid: row.uuid } })
        }
      }
    },
    async handleCheckVisibleForDuplicates () {
      // 'async await' is awesome
      // - You can still double-click a row to 'convert' it after executing this
      this.fetchingAllDuplicates = true
      for (const row of this.filteredResults) {
        await this.checkForDuplicates(row)
      }
      this.fetchingAllDuplicates = false
    },
    handleSortByDuplicates (a, b) {
      return (a.duplicates && a.duplicates.length)
        ? (b.duplicates && (b.duplicates.length > a.duplicates.length) ? -1 : 1)
        : -1
    },
    handleImportSelected () {
      this.$router.push({ name: 'ImportSelected' })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$refs.search.focus()
    })
  }
}

</script>
<style scoped>
.main-search>>>.el-form-item {
  margin: 0 0 3px 0;
}

.search-card {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 140px;
}

.logo {
  position: absolute;
  right: 15px;
  top: 15px;
  height: 70px;
}

.results-card {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(100% - 170px);
}

.table-wrapper {
  position: absolute;
  /* components/ButtonBar.vue height = 35px */
  bottom: 35px;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.results-card>>>.el-card__body {
  padding: 0;
  height: 100%;
}

.results-card>>>.el-table {
  border-color: #c5c5c5 !important;
}

.results-card>>>.el-table thead th {
  color: #444;
  font-weight: 400;
  background: #efefef;
  border-color: #c5c5c5 !important;
}

.results-card>>>.el-table .cell,
.results-card>>>.el-table .caret-wrapper {
  height: 18px;
  line-height: 18px;
}

.results-card>>>.el-table .sort-caret.ascending {
  top: -3px;
}

.results-card>>>.el-table .sort-caret.descending {
  bottom: -2px;
}

.results-card>>>.el-table__empty-text {
  font-size: 1.3em;
}

.results-card>>>td.icon-container .cell {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
}

td.icon-container i+i {
  margin-left: 2px;
}

.el-icon-question,
.el-icon-warning,
.el-icon-check {
  font-size: 14px;
}

.el-table .waiting-row {
  background: #ffd5d5;
}

.el-table__row {
  transition: color ease .5s;
}

.warning-row {
  background: oldlace;
}

/* Remove the "select-all" checkbox from the el-table */
.main-page>>>thead .el-table-column--selection .cell {
  display: none;
}

.count-wrapper {
  text-align: center;
  color: #686868;
  line-height: 28px;
  font-weight: 500;
  font-size: 12px;
}

.filter-controls {
  float: right;
  margin-top: 4px;
  margin-right: 40px;
}

</style>
