import Vue from 'vue'
import Vuex from 'vuex'
import pathify from './pathify'

import { v4 as uuid } from 'uuid'
import { make } from 'vuex-pathify'
import countries from 'i18n-iso-countries'

import http from '@/services/http'
import router, { importViews } from '@/router'

import SuperOffice from './SuperOffice'

// Modules
import admin from './modules/admin'
import update from './modules/update'

// Data
import {
  simpleFilters,
  resultsFilters
} from './data'

Vue.use(Vuex)

/**
 * Generate export settings based on prospects and state
 * @param {Object/Array} data Prospect(s) to import
 * @param {Vuex.state.Object} state Vue application state
 * @returns Object
 */
const generateExportSettings = (data, state) => {
  const { associate, category, business, country, selectionName, includePersons } = state.selected
  return {
    Prospects: data,
    Settings: {
      AssociateId: associate,
      CategoryId: category,
      BusinessId: business,
      SelectionName: selectionName,
      IncludePersons: includePersons,
      CountryId: country
    }
  }
}

/**
 * Instantiate and return an interval polling the FindProspects API to prevent session death
 * - Polls once a minute
 */
const keepAlive = () => {
  return setInterval(() => {
    http.post('/Home/KeepSessionAlive', JSON.stringify('Are we friends?')).then(response => {
      console.log(response.data)
    }).catch(err => {
      console.error(err)
    })
  }, 1000 * 60)
}

/**
 * Store
 */
const state = {
  simpleFilters,
  resultsFilters,
  keepAliveInterval: null,
  MAX_IMPORT: 20,
  loading: false,
  ready: false,
  icons: {
    person: 'so-icon--person',
    contact: 'so-icon--contact'
  },
  user: {},
  license: {},
  notifications: [],
  messages: [],
  settings: {},
  countries: [],
  associates: [],
  categories: [],
  businesses: [],
  preferences: {
    country: 0,
    simpleFilter: 0
  },
  query: '',
  searchResults: [],
  duplicates: [],
  selected: {
    prospects: [],
    associate: null,
    category: null,
    business: null,
    country: null,
    selectionName: '',
    includePersons: 1
  },
  exportRules: {
    associate: [{ required: true, trigger: 'change', message: 'Associate is required' }],
    category: [{ required: true, trigger: 'change', message: 'Category is required' }],
    business: [{ required: true, trigger: 'change', message: 'Business is required' }],
  }
}

const getters = {
  // vuex-pathify: make getters for defined state properties
  ...make.getters(state),
  locale: state => state.user.Locale,
  // username: state => state.user.Username,
  userName: state => `${state.user.Firstname} ${state.user.Lastname}`,
  // Get evaluated search config state
  searchPayload: state => ({
    ...(state.simpleFilters[state.preferences.simpleFilter].config || {}), // element by index, gives: { Companies: true/false, People: true/false }
    Freetext: state.query,
    CountryId: state.preferences.country
  }),
  // Get truth for how we import entities
  importEverythingAsCompany: state => state.settings.ImportPersonAs !== 'person',
  // Get icon for an prospect
  entityIcon: state => entityType => state.icons[entityType] || state.icons.contact,
  // Get the correct import view for an prospect; respects ImportPersonAs setting
  importViewByEntityType: (state, getters) => type => getters.importEverythingAsCompany
    ? importViews.find(v => v.type === 'contact')
    : importViews.find(v => v.type === type),
  // Get prospect in searchResults by established 'uuid'
  prospectByUuid: state => uuid => state.searchResults.find(i => i.uuid === uuid),
  // Get duplicates by prospect 'uuid'
  countryAlphaByNum: _ => num => countries.numericToAlpha2(num),
  countryNameByNum: _ => num => countries.getName(num, 'en'),
  // Get export settings model (shared by every import method)
  exportModel: state => {
    const { associate, category, business } = state.selected
    return { associate, category, business }
  },
  isAdmin: state => state.settings.FunctionalRights.includes('admin-all')
}

const actions = {
  message: ({ commit }, message) => {
    commit('message', message)
  },
  notification: ({ commit }, notification) => {
    commit('notification', notification)
  },
  getSettings: ({ commit }) => {
    return http.get('/Settings/GetSettings').then(response => {
      commit('settings', response.data)
    })
  },
  getCountries: ({ commit }) => {
    return http.get('/SuperOffice/GetCountries').then(response => {
      commit('countries', response.data)
    })
  },
  getAssociates: ({ commit }) => {
    return http.get('/SuperOffice/GetAssociates').then(response => {
      commit('associates', response.data)
      commit('defaultValue', {
        key: 'associate',
        value: (response.data.find(i => i.Selected) || {}).AssociateId
      })
    })
  },
  getCategories: ({ commit }) => {
    return http.get('/SuperOffice/GetCategories').then(response => {
      commit('categories', response.data)
      commit('defaultValue', {
        key: 'category',
        value: (response.data.find(i => i.Selected) || {}).Id
      })
    })
  },
  getBusinesses: ({ commit }) => {
    return http.get('/SuperOffice/GetBusinesses').then(response => {
      commit('businesses', response.data)
      commit('defaultValue', {
        key: 'business',
        value: (response.data.find(i => i.Selected) || {}).Id
      })
    })
  },
  getPreference: ({ commit }, { key, fallback }) => {
    return http.get('/Settings/GetPreference', { params: { key } }).then(response => {
      const value = (typeof fallback === 'number' && Number(response.data)) || fallback
      if (key === 'country') {
        commit('country', value)
      } else {
        commit('preference', { key, value })
      }
    })
  },
  getLicense: ({ commit }) => {
    return http.get('/License').then(response => {
      commit('license', response.data)
    })
  },
  // Initialize app by getting required state and settings
  init: ({ commit, getters, dispatch }) => {
    commit('loading', true)
    return Promise.all([
      dispatch('getLicense'),
      dispatch('getSettings'),
      dispatch('getCountries'),
      dispatch('getAssociates'),
      dispatch('getCategories'),
      dispatch('getBusinesses'),
    ]).then(() => {
      return Promise.all([
        dispatch('getPreference', { key: 'country', fallback: getters.countries[0].Id }),
        dispatch('getPreference', { key: 'simpleFilter', fallback: 0 })
      ])
    }).then(() => {
      return http.get('/api/user/current')
    }).then(response => {
      commit('user', response.data)
      commit('loading', false)
      commit('keepAliveInterval', keepAlive())
      return response.data.Locale
    }).catch(err => {
      console.error(err)
      commit('notification', {
        title: 'Error',
        message: 'Could not fetch necessary data.',
        type: 'error',
        duration: 0
      })
    })
  },
  // Perform search after prospecs based on query
  doSearch: async ({ commit, getters, dispatch }, query) => {
    if (query.length > 2) {
      commit('loading', true)
      return http.post('/SuperOffice/DoSearch', getters.searchPayload, { stopSchedule: true }).then(response => {
        const results = response.data.map(prospect => ({
          ...prospect,
          uuid: uuid(),
          duplicates: null,
          fetchingDuplicates: false,
          imported: false,
          persons: prospect.persons || []
        }))
        commit('searchResults', results)
        commit('loading', false)
      })
    } else {
      const error = new Error('Search query must be longer than 2 characters')
      commit('message', {
        type: 'warning',
        message: error.message
      })
      throw error
    }
  },
  // Check a prospect for duplicates in SuperOffice
  checkForDuplicates: async ({ commit }, prospect) => {
    if (!prospect.duplicates) {
      try {
        commit('fetchingDuplicates', { prospect, value: true })
        const { data: duplicates } = await http.post('/api/duplicate', prospect) // response.{data} => duplicates
        commit('duplicates', { prospect, duplicates })
      } catch (error) {
        commit('error', error)
        throw error
      } finally {
        commit('fetchingDuplicates', { prospect, value: false })
      }
    }
  },
  // Sends selected prospect to the backend for conversion according to set field-mapping
  // - Adds 'uuid'-property to person entities in the 'persons' Array for frontend handling
  convertProspect: async ({ commit, dispatch }, prospect) => {
    try {
      if (!prospect.duplicates && !prospect.fetchingDuplicates) {
        await dispatch('checkForDuplicates', prospect)
      }
      const { data } = await http.post('/api/prospect/convert', prospect, { priority: 1 })
      // commit('country', data.country) // TODO: Maybe not smart?
      return { ...data, persons: data.persons.map(p => ({ ...p, uuid: uuid() })) }
    } catch (err) {
      commit('error', err)
      return err
    }
  },
  importProspects: async ({ commit, state, dispatch }, { data, redirect = false }) => {
    try {
      commit('loading', true)
      const prospects = data instanceof Array ? data : [data]
      const exportSettings = generateExportSettings(prospects, state)
      const { data: results } = await http.post('/api/prospect/import', exportSettings, { priority: 1 })
      commit('imported', prospects)
      // Handle redirect
      if (redirect) {
        const { protocol } = results // The protocol we received as the 'import' response
        const { IsWindowsClient, SupportCrossMessaging } = state.settings // Fetched settings
        if (IsWindowsClient) {
          // Redirect within
          window.location = `superoffice:${protocol}`
        } else {
          // Use SuperOfficeCrossMessaging
          if (SupportCrossMessaging) {
            SuperOffice.ClientCrossMessaging.executeSoProtocol(protocol)
          } else {
            // Use default.aspx
            window.location = state.settings.SoProtocolBaseUrl + protocol
          }
        }
      } else {
        const count = prospects.length
        commit('notification', {
          type: 'success',
          title: 'Import',
          message: `Successfully imported ${count} prospect${count > 1 ? 's' : ''}!`
        })
        // Go one back from import dialog
        router.go(-1)
      }
    } catch (error) {
      // console.log('error', error)
      if (error.type === 'message') {
        commit('message', { type: 'warning', message: error.message })
      } else {
        commit('error', error)
      }
    } finally {
      commit('loading', false)
    }
  },
  sendFeedback: async ({ commit }, body) => {
    try {
      commit('loading', true)
      await http.post('/Feedback/SendFeedback', { Feedback: body }, { priority: 1 })
      commit('notification', {
        type: 'success',
        message: 'Feedback successfully submitted! Thank you!'
      })
    } catch (error) {
      commit('error', error)
    } finally {
      commit('loading', false)
    }
  }
}

const mutations = {
  ...make.mutations(state),
  notification: (state, notification) => {
    state.notifications.push(notification)
  },
  message: (state, message) => {
    state.messages.push(message)
  },
  preference: (state, { key, value }) => {
    state.preferences[key] = value
  },
  importPersonAs: (state, value) => {
    state.settings.ImportPersonAs = value
  },
  defaultValue: (state, { key, value }) => {
    state.selected[key] = value
  },
  searchResults: (state, prospects) => {
    state.searchResults = prospects
    state.duplicates = []
  },
  fetchingDuplicates: (state, { prospect, value }) => {
    const index = state.searchResults.findIndex(i => i.uuid === prospect.uuid)
    if (index > -1) {
      state.searchResults[index].fetchingDuplicates = value
    }
  },
  duplicates: (state, { prospect, duplicates }) => {
    const index = state.searchResults.findIndex(i => i.uuid === prospect.uuid)
    if (index > -1) {
      state.searchResults[index].duplicates = duplicates
    }
  },
  country: (state, country) => {
    state.preferences.country = country
    state.selected.country = country
  },
  imported: (state, prospects) => {
    prospects.forEach(prospect => {
      const index = state.searchResults.findIndex(i => i.uuid === prospect.uuid)
      if (index > -1) {
        state.searchResults[index].imported = true
      }
    })
    if (prospects.length > 1) {
      state.selected.selectionName = ''
    }
  },
  error: (state, error) => {
    const message = error.response.data.ExceptionMessage ? error.response.data.ExceptionMessage : error.message
    console.log('ExceptionMessage', error.response.data.ExceptionMessage)
    console.log('Message', error.message)
    state.notifications.push({ title: 'Error!', type: 'error', message, duration: 0 })
  },
  selectedProspects: (state, prospects) => {
    state.selected.prospects = prospects
  }
}

const store = new Vuex.Store({
  plugins: [
    pathify.plugin
  ],
  state,
  getters,
  mutations,
  actions,
  modules: {
    admin,
    update
  }
})

/* Store.watchers */

// Watch state.preferences.country: POST new value to API
store.watch(state => state.preferences.country, (newValue) => {
  http.post('/Settings/SetPreference', { key: 'country', value: newValue }, { priority: 1 })
})

// Watch state.preferences.simpleFilter: POST new value to API
store.watch(state => state.preferences.simpleFilter, (newValue) => {
  http.post('/Settings/SetPreference', { key: 'simpleFilter', value: newValue }, { priority: 1 })
})

export default store
