<template>

<div>
    <h1>License issue</h1>

    {{ message }}
</div>

</template>

<script>

export default {
  data () {
    return {
      message: 'A license issue has been detected. Please contact the administrator for further assistance.'
    }
  }
}

</script>
